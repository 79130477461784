import React, { Component, Fragment } from "react"
import { navigate } from "gatsby"

import { withFirebase } from "../Firebase"

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
}

// const ERROR_CODE_ACCOUNT_EXISTS =
//   "auth/account-exists-with-different-credential"
//
// const ERROR_MSG_ACCOUNT_EXISTS = `
//   An account with an E-Mail address to
//   this social account already exists. Try to login from
//   this account instead and associate your social accounts on
//   your personal account page.
// `

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { email, password } = this.state

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        // console.log("signin index navigate:", this.props.redirect)
        navigate('/'+this.props.redirect)
      })
      .catch(error => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, password, error } = this.state

    const isInvalid = password === "" || email === ""

    return (
      <Fragment>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
              className="form-control"
            />
          </div>

          <div className="form-group">
            <input
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
              className="form-control"
            />
          </div>

          <button disabled={isInvalid} type="submit" className="default-btn">
            <i className="bx bxs-hot" />
            Login
            <span />
          </button>
          {error && <p>{error.message}</p>}
        </form>
      </Fragment>
    )
  }
}

const SignInForm = withFirebase(SignInFormBase)

export default SignInForm
