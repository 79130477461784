import React, { Fragment } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SignInForm from "../components/SignIn"
import { PasswordForgetLink } from "../components/PasswordForget"
import logo from "../images/logo-70px.png"

const SignInPage = props => (
  <Fragment>
    <section className="login-area">
      <div className="row m-0">
        <div className="col-lg-12 col-md-12 pb-70">
          <div className="login-content">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="login-form">
                  <div className="logo">
                    <img src={logo} alt="logo" />
                  </div>
                  <h3>Welcome back</h3>
                  <p>
                    New to HRS Systems?{" "}
                    <Link to="/signup" state={{ redirect: props.redirect }}>
                      Sign up
                    </Link>
                  </p>
                  <br />
                  <p>
                    If you had an account on the previous version of our website
                    please sign up again{" "}
                    <Link to="/signup" state={{ redirect: props.redirect }}>
                      here
                    </Link>
                  </p>
                  <SignInForm redirect={props.redirect} />
                  <PasswordForgetLink />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
)

function getRedirect(location) {
  if (location) {
    if (location.state) {
      return location.state.redirect ? location.state.redirect : ""
    } else {
      return ""
    }
  } else {
    return ""
  }
}

export default ({ location }) => (
  <Layout title="Login" description={"Access your HRS Systems account, software licenses, and more."}>
    <SignInPage redirect={getRedirect(location)} className="pb-3" />
  </Layout>
)
