import React, { Component } from "react"
import { Link } from "gatsby"

import { withFirebase } from "../Firebase"
import * as ROUTES from "../../constants/routes"

const INITIAL_STATE = {
  email: "",
  error: null,
}

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { email } = this.state

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
      })
      .catch(error => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, error } = this.state

    const isInvalid = email === ""

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
            className="form-control"
          />
        </div>
        <button disabled={isInvalid} type="submit" className="default-btn">
          <i className="bx bxs-send" />
          Reset My Password
          <span />
        </button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot your password?</Link>
  </p>
)

export default withFirebase(PasswordForgetForm)

export { PasswordForgetLink }
